import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IHomeActive from '@ifca-root/react-component/src/assets/icons/home-w.svg'
import IHome from '@ifca-root/react-component/src/assets/icons/home.svg'
import ILogOutActive from '@ifca-root/react-component/src/assets/icons/logout-w.svg'
import MyMenu from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/my-menu.svg'
import OnBehalfSvg from '@ifca-root/react-component/src/assets/icons/OnBehalfIcon.svg'
import SubordinateSvg from '@ifca-root/react-component/src/assets/icons/subordinate.svg'
import ProjectExpenseManagement from '@ifca-root/react-component/src/assets/icons/Project Expense Management/Project Main Menu/expense-claim.svg'
import ClaimManagement from '@ifca-root/react-component/src/assets/icons/Claim Management/Claim Main Menu/claim.svg'
import RequisitionManagement from '@ifca-root/react-component/src/assets/icons/Requisition Managment/requisition.svg'
import SystemAdmin from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/system-admin.svg'
import PersonnelManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/personnel-management.svg'
import PayrollManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/payroll-management.svg'
import LeaveManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/leave.svg'
import TimeAttendance from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/time-attendance.svg'
import Timesheet from '@ifca-root/react-component/src/assets/icons/Timesheet/TS Main Menu/timesheet.svg'
import Recruitment from '@ifca-root/react-component/src/assets/icons/Recruitement/Recruitement Main Menu/recruitment.svg'
import StaffAppraisal from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/staff-appraisal.svg'
import BusinessInsight from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/business-insight.svg'
import DigitalReporting from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/digital-reporting.svg'
import Helpdesk from '../../assets/icons/helpdesk-assignee.svg'
import Training from '@ifca-root/react-component/src/assets/icons/training.svg'
import Logout from '@ifca-root/react-component/src/assets/icons/logout.svg'
import Profile from 'assets/icons/profile.svg'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import Authentication from '@ifca-root/react-component/src/assets/icons/authentication.svg'
import {
  HrPermission,
  useCheckOnPremServerQuery,
  useCompanyListingByEmailLazyQuery,
  useCompanyListingLazyQuery,
  useGetEmployeeAppSecurityAssignmentListingLazyQuery,
  useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery,
  useGetRoleByUserIdLazyQuery,
  useGetRolePermissionByUserLazyQuery,
  useIsSuperUserEmployeeLazyQuery,
  useLoggedInEmployeeProfileQuery,
  useLoggedInUserProfileQuery,
  useMyEmployeeInfoLazyQuery,
  useUserProfilePicLazyQuery,
  useLoginUserPermsQuery,
  useLoginUserPermsLazyQuery,
  useLoggedInUserProfileLazyQuery,
  useLoggedInEmployeeProfileLazyQuery,
} from '@hr-root/hr-employee-react/src/generated/graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import {
  GET_PACKAGES_DETAIL,
  GET_ACCOUNT_DETAIL,
} from '@hr-root/hr-employee-react/src/containers/HomeModule/AccountQuery'
import { hrNodeUrl } from '@hr-root/hr-employee-react/src/HRClient'

import { AccountClient } from '@hr-root/hr-employee-react/src/client'
import { redirectToAnotherApp } from './AnotherAppRedirection'
import Skeleton from '@material-ui/lab/Skeleton/Skeleton'

export const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        // maxWidth: drawerWidth,
        minWidth: '240px!important',
        flexShrink: 0,
      },
      [theme.breakpoints.up('xs')]: {
        // width: drawerWidth,
        // maxWidth: drawerWidth,
        minWidth: '0px!important',
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,

      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
  mobile?: boolean
  loading: boolean
}

export const Map = [
  {
    module: 'Home',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'My Menu',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'On Behalf',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'My Subordinate',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'System Admin',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-admin-uat.hrx.asia',
    dev_url: 'hrx-dev-admin.ifca.io',
    local_url: 'localhost:3006',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Personnel Management',
    ifca_url: 'ifcahome-personnel.hrx.asia',
    prod_url: 'hrms-personnel.hrx.asia',
    uat_url: 'hrms-personnel-uat.hrx.asia',
    dev_url: 'hrx-dev-personnel.ifca.io',
    local_url: 'localhost:3007',
    sehsa_url: '172.17.10.95:88',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-personnel-demo.hrx.asia',
    eon_url: 'employee-personnel.eon.com.my',
    eon_uat_url: 'employee-uat-personnel.eon.com.my',
  },
  {
    module: 'Payroll Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Leave Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-leave-uat.hrx.asia',
    dev_url: 'hrx-dev-leave.ifca.io',
    local_url: 'localhost:3013',
    sehsa_url: '172.17.10.95:92',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-leave-demo.hrx.asia',
    eon_url: 'employee-leave.eon.com.my',
    eon_uat_url: 'employee-uat-leave.eon.com.my',
  },
  {
    module: 'General Claim Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-claim-uat.hrx.asia',
    dev_url: 'hrx-dev-claim.ifca.io',
    local_url: 'localhost:3012',
    sehsa_url: '172.17.10.95:86',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-claim-demo.hrx.asia',
    eon_url: 'employee-claim.eon.com.my',
    eon_uat_url: 'employee-uat-claim.eon.com.my',
  },
  {
    module: 'Project Expense Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Requisition Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-requisition-uat.hrx.asia',
    dev_url: 'hrx-dev-requisition.ifca.io',
    local_url: 'localhost:3011',
    sehsa_url: '172.17.10.95:89',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-requisition-demo.hrx.asia',
    eon_url: 'employee-requisition.eon.com.my',
    eon_uat_url: 'employee-uat-requisition.eon.com.my',
  },
  {
    module: 'Time Attendance',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-tms.ifca.io',
    local_url: 'localhost:3014',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Timesheet',
    ifca_url: 'ifcahome-timesheet.hrx.asia',
    prod_url: 'hrms-timesheet.hrx.asia',
    uat_url: 'hrms-timesheet-uat.hrx.asia',
    dev_url: 'hrx-dev-timesheet.ifca.io',
    local_url: 'localhost:3019',
    sehsa_url: '172.17.10.95:90',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-timesheet-demo.hrx.asia',
    eon_url: 'employee-timesheet.eon.com.my',
    eon_uat_url: 'employee-uat-timesheet.eon.com.my',
  },
  {
    module: 'Performance Management',
    ifca_url: 'ifcahome-appraisal.hrx.asia',
    prod_url: 'hrms-appraisal.hrx.asia',
    uat_url: 'hrms-appraisal-uat.hrx.asia',
    dev_url: 'hrx-dev-appraisal.ifca.io',
    local_url: 'localhost:3008',
    sehsa_url: '172.17.10.95:84',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-appraisal-demo.hrx.asia',
    eon_url: 'employee-appraisal.eon.com.my',
    eon_uat_url: 'employee-uat-appraisal.eon.com.my',
  },
  {
    module: 'Business Insight',
    ifca_url: 'ifcahome-businessinsight.hrx.asia',
    prod_url: 'hrms-businessinsight.hrx.asia',
    uat_url: 'hrms-businessinsight-uat.hrx.asia',
    dev_url: 'hrx-dev-businessinsight.ifca.io',
    local_url: 'localhost:3016',
    sehsa_url: '172.17.10.95:85',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-businessinsight-demo.hrx.asia',
    eon_url: 'employee-businessinsight.eon.com.my',
    eon_uat_url: 'employee-uat-businessinsight.eon.com.my',
  },
  {
    module: 'Digital Reporting',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Helpdesk',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-uat.hrx.asia',
    dev_url: 'hrx-dev-employee.ifca.io',
    local_url: 'localhost:3010',
    sehsa_url: '172.17.10.95',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-demo.hrx.asia',
    eon_url: 'employee.eon.com.my',
    eon_uat_url: 'employee-uat.eon.com.my',
  },
  {
    module: 'Training Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-training-uat.hrx.asia',
    dev_url: 'hrx-dev-training.ifca.io',
    local_url: 'localhost:3018',
    sehsa_url: '172.17.10.95:91',
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-training-demo.hrx.asia',
    eon_url: 'employee-training.eon.com.my',
    eon_uat_url: 'employee-uat-training.eon.com.my',
  },
  {
    module: 'Recruitment Management',
    ifca_url: 'ifcahome.hrx.asia',
    prod_url: 'hrms.hrx.asia',
    uat_url: 'hrms-recruitment-uat.hrx.asia',
    dev_url: 'hrx-dev-recruitment.ifca.io',
    local_url: 'localhost:3020',
    sehsa_url: '172.17.10.95', // check port
    mbi_url: 'smarthrx.mbiselangor.com.my',
    demo_url: 'hrms-recruitment-demo.hrx.asia',
    eon_url: 'employee-recruitment.eon.com.my',
    eon_uat_url: 'employee-uat-recruitment.eon.com.my',
  },
]

export default function LayoutDrawer(props: Props) {
  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const {
    windows,
    open,
    onCloseDrawer,
    variant,
    mobile,
    loading: parentLoading = false,
    ...rest
  } = props
  const classes = useStyles()
  const container =
    windows !== undefined ? () => windows().document.body : undefined
  let history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [loggedInEmployeeProfile, setLoggedInEmployeeProfile] = useState(null)
  const [permissionCheckLoading, setPermissionCheckLoading] = useState(true)

  const [loggedInUserProfile, setLoggedInUserProfile] = useState(null)
  const [myMenu, setmyMenu] = useState(false)
  const [mySubordinate, setmySubordinate] = useState(false)
  const [myHelpDesk, setmyHelpDesk] = useState(false)

  const [myLeave, setMyLeave] = useState(false)
  const [myClaim, setMyClaim] = useState(false)
  const [payroll, setPayroll] = useState(false)
  const [myRequisition, setMyRequisition] = useState(false)
  const [myTimesheet, setMyTimesheet] = useState(false)
  const [projectExpense, setProjectExpense] = useState(false)
  const [myPerformance, setPerformance] = useState(false)
  const [myTMS, setMyTMS] = useState(false)
  const [myTraining, setTraining] = useState(false)
  const [myRecruitment, setRecruitment] = useState(false)
  const [subPackage, setSubPackage] = useState(null)
  const [fetchLoading, setFetchLoading] = useState(false)

  let env = ''
  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
      env = 'ifca_url'
      break
    case 'prod-v5-build':
      env = 'prod_url'
      break
    case 'uat-v5-build':
      env = 'uat_url'
      break
    case 'dev-v2-build':
      env = 'dev_url'
      break
    case 'prod-sehsa-build':
      env = 'sehsa_url'
      break
    case 'prod-mbi-build':
      env = 'mbi_url'
      break
    case 'demo-build':
      env = 'demo_url'
      break
    case 'prod-eon-build':
      env = 'eon_url'
      break
    case 'uat-eon-build':
      env = 'eon_uat_url'
      break
    default:
      env = 'local_url'
  }

  useEffect(() => {
    console.log('portal:', window?.location?.href?.split('/')[2])
    console.log('target url:', env)
  }, [])

  const [getLoggedInUser] = useLoggedInUserProfileLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setLoggedInUserProfile(data?.loggedInUserProfile)
    },
  })

  const [getLoginUserPerms] = useLoginUserPermsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.LoginUserPerms)
        localStorage.setItem('userAccess', data?.LoginUserPerms)
    },
  })

  useEffect(() => {
    if (loggedInUserProfile) {
      getAccountDetail()
      getPackagesDetail()
      getCompanySummary()

      getUserProfilePic()
      getPackagesDetail()
      getRoleData()
      getRolePermissionByUserData()

      if (loggedInUserProfile?.employeeID) {
        getIsSuperUserEmpData()

        LoadEmployeeAppSecurityPermissionsByContactID({
          variables: {
            ContactID: '',
            Permissions: [
              HrPermission.HomeMymenuRead,
              HrPermission.HomeMysubordinateRead,
              HrPermission.HomeHelpdeskRead,
              HrPermission.HomeDashboardRead,
              HrPermission.HomeOnBehalfRead,
            ],
            EmployeeID: loggedInUserProfile?.employeeID,
          },
        })
      }
      if (loggedInUserProfile?.isEmployee) {
        getEmployeeSummary()
        getCompanyListingByEmail()

        loadEmployeeAppSecurityAssignmentByCompany({
          variables: {
            EmployeeID: loggedInUserProfile?.employeeID,
          },
        })
      }
    }
  }, [loggedInUserProfile])

  const [getLoggedInEmployeeProfile] = useLoggedInEmployeeProfileLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setLoggedInEmployeeProfile(data?.loggedInEmployeeProfile)
    },
  })

  const {
    data: { CheckOnPremServer } = { CheckOnPremServer: null },
  } = useCheckOnPremServerQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      localStorage.setItem('OnPremServer', data?.CheckOnPremServer)
    },
  })

  const [
    getEmployeeSummary,
    {
      loading: employeeLoading,
      called: myEmployeeCalled,
      data: { MyEmployeeInfo } = { MyEmployeeInfo: null },
    },
  ] = useMyEmployeeInfoLazyQuery({
    variables: {
      EmployeeID: loggedInUserProfile?.employeeID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    loadEmployeeAppSecurityAssignmentByCompany,
    { loading: CompanyEmployeeAppSecurityLoading },
  ] = useGetEmployeeAppSecurityAssignmentListingLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [
    getCompanyListingByEmail,
    {
      data: { CompanyListingByEmail } = {
        CompanyListingByEmail: [],
      },
      loading: getCompanyListingByEmailLoading,
    },
  ] = useCompanyListingByEmailLazyQuery({
    variables: {
      EmployeeEmail: loggedInUserProfile?.email,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getAccountDetail,
    { data: accountData, loading: getAccountDetailLoading },
  ] = useLazyQuery(
    GET_ACCOUNT_DETAIL,

    {
      client: AccountClient,
      variables: { accountID: loggedInUserProfile?.accountID },
    }
  )

  const [
    getCompanySummary,
    {
      data: { CompanyListing } = { CompanyListing: [] },
      loading: companyListingLoading,
    },
  ] = useCompanyListingLazyQuery({
    variables: {
      SubscriptionAccountID: loggedInUserProfile?.accountID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getPackagesDetail,
    { data: packageData, loading: getPackagesDetailLoading },
  ] = useLazyQuery(GET_PACKAGES_DETAIL, {
    client: AccountClient,
    variables: {
      softwareCode: 'HR',
      accountID: loggedInUserProfile?.accountID,
    },
  })

  const [
    getUserProfilePic,
    { data: UserProfilePic },
  ] = useUserProfilePicLazyQuery({
    variables: {
      UserID: loggedInUserProfile?.isEmployee
        ? loggedInUserProfile?.employeeID
        : loggedInUserProfile?.ID,
      IsEmployee: loggedInUserProfile?.isEmployee,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getRoleData,
    { data: { getRole } = { getRole: [] } },
  ] = useGetRoleByUserIdLazyQuery({
    variables: {
      UserID: loggedInUserProfile?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getIsSuperUserEmpData,
    { data: { IsSuperUserEmployee } = { IsSuperUserEmployee: false } },
  ] = useIsSuperUserEmployeeLazyQuery({
    variables: {
      EmployeeID: loggedInUserProfile?.employeeID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getRolePermissionByUserData,
    {
      data: { getRolePermissionByUser } = {
        getRolePermissionByUser: [],
      },
    },
  ] = useGetRolePermissionByUserLazyQuery({
    variables: {
      hrPermissions: [
        HrPermission.HomeAdminExecSummaryRead,
        HrPermission.HomePersonnelExecSummaryRead,
        HrPermission.HomeClaimExecSummaryRead,
        HrPermission.HomePayrollExecSummaryRead,
        HrPermission.HomeLeaveExecSummaryRead,
        HrPermission.HomeRequisitionExecSummaryRead,
        HrPermission.HomeTmsExecSummaryRead,
        HrPermission.HomeTimesheetRead,
        HrPermission.HomeDigitalReportingView,
        HrPermission.HomeProjectExecSummaryRead,
        HrPermission.HomePerformanceRead,
        HrPermission.HomeHelpdeskRead,
        HrPermission.HomeBusinessInsightsRead,
        HrPermission.HomeTrainingRead,
        HrPermission.HomeRecruitmentRead,
      ],
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    let subPackages: any[] = []

    if (packageData) {
      setSubPackage(packageData?.getPackageByAccount)
      for (var obj in packageData) {
        var data = packageData[obj]
        for (var d in data) {
          if (data[d] === 'CLAIMMANAGEMENT') {
            setMyClaim(true)
            subPackages.push('CLAIM')
          } else if (data[d] === 'PAYROLL') {
            setPayroll(true)
            subPackages.push('PAYROLL')
          } else if (data[d] === 'LEAVEMANAGEMENT') {
            setMyLeave(true)
            subPackages.push('LEAVE')
          } else if (data[d] === 'REQUISITIONMANAGEMENT') {
            setMyRequisition(true)
            subPackages.push('REQUISITIONMANAGEMENT')
          } else if (data[d] === 'TIMESHEET') {
            setMyTimesheet(true)
            subPackages.push('TIMESHEET')
          } else if (data[d] === 'TRAININGDEVELOPMENT') {
            setTraining(true)
            subPackages.push('TRAININGDEVELOPMENT')
          } else if (data[d] === 'TIMEATTENDANCE') {
            setMyTMS(true)
            subPackages.push('TIMEATTENDANCE')
          } else if (data[d] === 'PROJECTEXPENSEMANAGEMENT') {
            setProjectExpense(true)
            subPackages.push('PROJECTEXPENSEMANAGEMENT')
          } else if (data[d] === 'STAFFAPPRAISAL') {
            setPerformance(true)
            subPackages.push('STAFFAPPRAISAL')
          } else if (data[d] === 'RECRUITMENT') {
            setRecruitment(true)
            subPackages.push('RECRUITMENT')
          } else {
            subPackages.push(data[d])
          }
          // setRecruitment(true)
          // subPackages.push('RECRUITMENT')
        }
      }
      localStorage.setItem('subPackages', JSON.stringify(subPackages))
    }
  }, [packageData])

  useEffect(() => {
    if (
      window?.location?.href?.split('?')[1] != undefined &&
      (window?.location?.href?.split('?')[1]?.split('=')[0] == 'token' ||
        window?.location?.href
          ?.split('?')[1]
          ?.split('&')[1]
          ?.split('=')[0] == 'userID')
    ) {
      if (CompanyListing && loggedInUserProfile && accountData) {
        let Companies: any[] = CompanyListing

        let userData: any = {
          ID: loggedInUserProfile?.ID,
          accountID: loggedInUserProfile?.accountID,
          contactNo: loggedInUserProfile?.contactNo,
          department: loggedInUserProfile?.department,
          email: loggedInUserProfile?.email,
          jobTitle: loggedInUserProfile?.jobTitle,
          name: loggedInUserProfile?.name,
          accountName: accountData?.getAccountDetail?.name,
          superUser: loggedInUserProfile?.superUser,
          companyID:
            // if lastest accessed entity is null, get first companyid from company listing
            loggedInUserProfile?.lastestAccessedEntity ||
            Companies[0]?.CompanyID,
          lastestAccessedEntity: loggedInUserProfile?.lastestAccessedEntity,
          companyName:
            Companies.filter(
              i =>
                i.CompanyID ===
                (loggedInUserProfile?.lastestAccessedEntity ||
                  Companies[0]?.CompanyID)
            )[0]?.CompanyName || '',
          RoleIDs: loggedInUserProfile?.roleID,
          isEmployee: loggedInUserProfile?.isEmployee,
          helpdeskAssignee: loggedInUserProfile?.helpdeskAssignee,
        }
        localStorage.setItem('loggedInUser', JSON.stringify(userData))
      }

      if (loggedInEmployeeProfile && CompanyListingByEmail) {
        let CompanyByEmail: any[] = CompanyListingByEmail

        let employeeData = {
          email: loggedInEmployeeProfile?.email,
          employeeID:
            loggedInEmployeeProfile?.employeeID ||
            loggedInUserProfile?.employeeID,
          employeeNo: loggedInEmployeeProfile?.employeeNo,
          mobileNo: loggedInEmployeeProfile?.mobileNo,
          name: loggedInEmployeeProfile?.name,
          subscriptionAccountID: loggedInEmployeeProfile?.subscriptionAccountID,
          lastestAccessedEntity: loggedInEmployeeProfile?.LastestAccessedEntity,
          superuserID: loggedInEmployeeProfile?.SuperUserID,
          companyID: loggedInEmployeeProfile?.companyID,
          companyName:
            CompanyByEmail?.filter(
              i => i.CompanyID === loggedInEmployeeProfile?.companyID
            )[0]?.CompanyName || '',
          Salutation: loggedInEmployeeProfile?.Salutation,
        }

        localStorage.setItem('loggedInEmployee', JSON.stringify(employeeData))
      }

      if (MyEmployeeInfo && loggedInUserProfile) {
        let Employee: any = MyEmployeeInfo

        if (Employee !== null) {
          localStorage.setItem(
            'Employee',
            JSON.stringify(
              Object.assign(Employee, {
                Salutation: Employee?.Contact?.EmployeeSalutation?.Name || '',
              })
            )
          )
        }
      }
      if (loggedInUserProfile) {
        let Employee: any = MyEmployeeInfo
        localStorage.setItem(
          'RoleID',
          JSON.stringify({
            EmployeeID: loggedInUserProfile?.employeeID,
            Name: loggedInUserProfile?.name,
            superUser: loggedInUserProfile?.superUser,
            RoleID: Employee?.RoleID || loggedInUserProfile?.roleID,
            RoleName: Employee?.RoleName,
          })
        )
      }

      if (
        localStorage.getItem('loggedInUser') &&
        localStorage.getItem('loggedInEmployee') &&
        localStorage.getItem('RoleID') &&
        (!loggedInUserProfile?.employeeID ||
          (loggedInUserProfile?.employeeID && localStorage.getItem('Employee')))
      ) {
        setTimeout(() => {
          window.history.pushState(
            null,
            null,
            window?.location?.href?.split('?')?.[0]
          )
        }, 3000)
      }
    }
  }, [
    getAccountDetailLoading,
    getPackagesDetailLoading,
    companyListingLoading,
    employeeLoading,
    getCompanyListingByEmailLoading,
    CompanyEmployeeAppSecurityLoading,
    loggedInEmployeeProfile,
    parentLoading,
  ])

  const [
    LoadEmployeeAppSecurityPermissionsByContactID,
    {
      data: { GetEmployeeAppSecurityPermissionsByContactID } = {
        GetEmployeeAppSecurityPermissionsByContactID: [],
      },
      loading,
    },
  ] = useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data.GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
          HrPermission.HomeHelpdeskRead
        )
      ) {
        console.log('setmyHelpDesk true')
        setmyHelpDesk(true)
      }
      if (
        data.GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
          HrPermission.HomeMymenuRead
        )
      ) {
        console.log('setmyMenu true')

        setmyMenu(true)
      }
      if (
        data.GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
          HrPermission.HomeMysubordinateRead
        )
      ) {
        console.log('setmySubordinate true')

        setmySubordinate(true)
      }

      setPermissionCheckLoading(false)
    },
  })

  const navigations = [
    {
      name: 'Home',
      path: '/home',
      icon: IHome,
      ACLIndex: 0,
      show: true,
      redirectAnother: true,
    },

    {
      name: 'My Menu',
      path: '/MyMenu',
      icon: MyMenu,
      ACLIndex: 0,
      show: !IsSuperUserEmployee ? false : myMenu,
      redirectAnother: true,
    },
    {
      name: 'My Subordinate',
      path: '/mysubordinates',
      icon: SubordinateSvg,
      iconSelected: ILogOutActive,
      ACLIndex: 20,
      show:
        !IsSuperUserEmployee ||
        loggedInEmployee?.subscriptionAccountID ==
          '3e7e7378-8ff1-11ed-80b5-fbe62d5ba329' ||
        loggedInEmployee?.subscriptionAccountID ==
          '8821a4b2-4480-11ed-80b5-db268676092e'
          ? false
          : mySubordinate,
      redirectAnother: true,
    },

    {
      name: 'On Behalf',
      path: '/OnBehalf',
      icon: OnBehalfSvg,
      ACLIndex: 0,
      show:
        !IsSuperUserEmployee ||
        loggedInEmployee?.subscriptionAccountID ==
          '3e7e7378-8ff1-11ed-80b5-fbe62d5ba329' ||
        loggedInEmployee?.subscriptionAccountID ==
          '8821a4b2-4480-11ed-80b5-db268676092e'
          ? false
          : GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
              HrPermission.HomeOnBehalfRead
            ),
      redirectAnother: true,
    },

    {
      name: 'System Admin',
      path: '/SystemAdmin/SystemAdminListing',
      icon: SystemAdmin,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeAdminExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Personnel Management',
      path: '/PersonnelManagementListing',
      icon: PersonnelManagement,
      iconSelected: IHomeActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomePersonnelExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Payroll Management',
      path: '/PayrollManagementListing',
      icon: PayrollManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        payroll &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomePayrollExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Leave Management',
      path: '/LeaveManagementListing',
      icon: LeaveManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myLeave &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeLeaveExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'General Claim Management',
      path: '/ClaimManagementListing',
      icon: ClaimManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myClaim &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeClaimExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Project Expense Management',
      path: '/ProjectExpenseListing',
      icon: ProjectExpenseManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        projectExpense &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeProjectExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Requisition Management',
      path: '/RequisitionManagementListing',
      icon: RequisitionManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myRequisition &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeRequisitionExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Time Attendance',
      path: '/TimeAttendanceManagementListing',
      icon: TimeAttendance,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myTMS &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeTmsExecSummaryRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Timesheet',
      path: '/TimesheetManagementListing',
      icon: Timesheet,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myTimesheet &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeTimesheetRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Recruitment Management',
      path: '/RecruitmentManagementListing',
      icon: Recruitment,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myRecruitment &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeRecruitmentRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Training Management',
      path: '/TrainingManagementListing',
      icon: Training,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myTraining &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeTrainingRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Performance Management',
      path: '/PerformanceManagementListing',
      icon: StaffAppraisal,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myPerformance &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomePerformanceRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Business Insight',
      path: '/BusinessInsightManagementListingV2',
      icon: BusinessInsight,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name != 'Employee' &&
        subPackage?.includes('BUSINESSINSIGHT') &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeBusinessInsightsRead
          )),
      redirectAnother: true,
    },
    {
      name: 'Digital Reporting',
      path: '/DigitalReportingManagementListing',
      icon: DigitalReporting,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name != 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeDigitalReportingView
          )),
      redirectAnother: true,
    },
    {
      name: 'Helpdesk',
      path: '/HelpdeskListing',
      icon: Helpdesk,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        subPackage?.includes('HELPDESK') &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeHelpdeskRead
          ) ||
          myHelpDesk),
      redirectAnother: true,
    },
    {
      name: 'Logout',
      path: '/logout',
      icon: Logout,
      ACLIndex: 0,
      show: true,
    },
  ]

  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('ACLIndex'))?.length === 0 &&
      !JSON.parse(localStorage.getItem('Employee'))?.EmployeeID
    ) {
      setInterval(() => {
        forceUpdate()
      }, 500)
    }
  }, [])

  useEffect(() => {
    if (parentLoading) return
    getLoggedInUser()
    getLoggedInEmployeeProfile()
    getLoginUserPerms()
  }, [parentLoading])

  console.log('getRolePermissionByUser: ', getRolePermissionByUser)
  const drawer =
    // Make sure App.tsx's useEffect is done before rendering the drawer
    !parentLoading &&
    // Wait for loggedInEmployeeProfile to be fetched
    JSON.parse(localStorage.getItem('loggedInEmployee')) &&
    // Wait for all permissions are done checking, prevent flickering
    (loggedInUserProfile?.superUser ||
      getRolePermissionByUser.length !== 0 ||
      !permissionCheckLoading) &&
    // Wait for permission is loaded
    !loading ? (
      // && !fetchLoading // Commented to prevent always shows loading skeleton when redirecting to another app
      <div className="route-container">
        <List disablePadding>
          {navigations
            ?.filter(x => x.show == true)
            ?.map(v =>
              !v?.redirectAnother ||
              window?.location?.href?.split('/')[2] ==
                Map?.find(m => m.module == v.name)?.[env] ? (
                <ListItem
                  button
                  dense
                  divider
                  component={NavLink}
                  to={v.path}
                  onClick={onCloseDrawer}
                >
                  <ListItemIcon>
                    <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary={v.name} />
                </ListItem>
              ) : (
                <ListItem
                  button
                  dense
                  divider
                  onClick={() => {
                    if (mobile == true) {
                      if (v?.name === 'Home') {
                        onCloseDrawer()
                      } else {
                        redirectToAnotherApp(
                          v?.path,
                          v?.name,
                          loggedInUserProfile?.ID,
                          Map,
                          true
                        )
                      }
                    } else {
                      setFetchLoading(true)
                      fetch(hrNodeUrl, {
                        method: 'POST',
                        credentials: 'include',
                        body: JSON.stringify({
                          query:
                            'mutation RedirectDomainToken($UserID: String!) { RedirectDomainToken(UserID: $UserID)}',
                          variables: { UserID: loggedInUserProfile?.ID },
                        }),
                        headers: { 'Content-Type': 'application/json' },
                      })
                        .then(async x => {
                          const token = await x.json()
                          console.log(token?.data?.RedirectDomainToken)
                          redirectToAnotherApp(
                            v?.path,
                            v?.name,
                            token?.data?.RedirectDomainToken,
                            Map
                          )
                        })
                        .catch(err => {
                          console.error(err)
                          window.alert('routing error!')
                        })
                    }
                  }}
                >
                  <ListItemIcon>
                    <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary={v.name} />
                </ListItem>
              )
            )}
        </List>
      </div>
    ) : (
      <div className="route-container">
        <List
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {Array.from(new Array(8)).map((_, index) => (
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height="3rem"
              aria-label="Loading menu"
            >
              <ListItem button dense divider></ListItem>
            </Skeleton>
          ))}
        </List>
      </div>
    )

  const userProfileAvatar = UserProfilePic?.UserProfilePic?.DocumentFile ? (
    <Avatar
      src={UserProfilePic?.UserProfilePic?.DocumentFile}
      className="avatar"
    >
      {/* fallback if cant load the image */}
      <Avatar alt="avatar" className="avatar" />
    </Avatar>
  ) : parentLoading || !loggedInUserProfile ? (
    <Skeleton animation="wave" variant="circle" aria-label="Loading avatar">
      <Avatar />
    </Skeleton>
  ) : (
    <Avatar alt="avatar" className="avatar" />
  )

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">{userProfileAvatar}</div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                {parentLoading ||
                !loggedInEmployee?.name ||
                !loggedInUserProfile ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="60%"
                    aria-label="Loading name"
                  />
                ) : loggedInUserProfile?.isEmployee ? (
                  `${loggedInEmployee?.Salutation || ''} ${
                    loggedInEmployee?.name
                  }`
                ) : (
                  `${loggedInUserProfile?.name}`
                )}
              </span>
            }
            secondary={
              parentLoading || !loggedInUserProfile ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="80%"
                  aria-label="Loading email"
                />
              ) : (
                <span className="desc">{loggedInUserProfile?.email}</span>
              )
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={handleProfileClick}
              disabled={parentLoading || !loggedInUserProfile}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        disableScrollLock={true}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Authentication} alt="" />{' '}
          Change Password
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/logout')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/logout')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Drawer
        {...rest}
        container={container}
        variant={variant}
        anchor={'left'}
        open={open}
        onClose={onCloseDrawer}
        className="drawer"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {userProfile}
        {drawer}
      </Drawer>
    </nav>
  )
}
